// custom

//$primary: #fa0101;
// $success: #9228a7;
// $danger: #dc3545;
// $warning: #ffa500;
// $info: #17a2b8;
// $light: #f8f9fa;
// //$dark: #343a40;

$breadcrumb-margin-bottom: 0rem;
// $breadcrumb-bg: white;
// $card-header-bg:white;

.gradient-bg {
  background: rgb(255, 255, 255);
  background: -moz-linear-gradient(
    0deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 129, 134, 1) 4%,
    rgba(177, 42, 91, 1) 67%
  );
  background: -webkit-linear-gradient(
    0deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 129, 134, 1) 4%,
    rgba(177, 42, 91, 1) 67%
  );
  background: linear-gradient(
    0deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 129, 134, 1) 4%,
    rgba(177, 42, 91, 1) 67%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffffff",endColorstr="#b12a5b",GradientType=1);
}

.bg-doaj-gradient {
  background: rgb(255, 255, 255);
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 138, 90, 1) 55%
  );
  border-top-width: 0.18em !important;
}

.bg-openalex-gradient {
  background: rgb(255, 255, 255);
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(175, 174, 173, 1) 18%,
    rgba(121, 120, 119, 1) 44%
  );
  border-top-width: 0.18em !important;
}

.bg-semantic-gradient {
  background: rgb(255, 255, 255);
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(130, 136, 167, 1) 1%,
    rgba(1, 12, 71, 1) 69%
  );
  border-top-width: 0.18em !important;
}

.bg-dimensions-gradient {
  background: rgb(255, 255, 255);
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 1) 20%,
    rgba(230, 232, 238, 1) 84%,
    rgba(188, 192, 208, 1) 100%
  );
  border-top-width: 0.18em !important;
}

.bg-altmetric-gradient {
  background: rgb(255, 255, 255);
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 1) 20%,
    rgba(234, 213, 237, 1) 84%,
    rgba(205, 177, 204, 1) 100%
  );
  border-top-width: 0.18em !important;
}

.bg-wos-gradient {
  background: rgb(255, 255, 255);
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 1) 18%,
    rgba(134, 135, 196, 1) 52%,
    rgba(0, 1, 131, 1) 94%
  );
  border-top-width: 0.18em !important;
}

.bg-scopus-gradient {
  background: rgb(255, 255, 255);
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 1) 18%,
    rgba(254, 169, 74, 1) 94%
  );
  border-top-width: 0.18em !important;
}

.bg-redib-gradient {
  background: rgb(255, 255, 255);
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 1) 18%,
    rgba(255, 84, 84, 1) 94%
  );
  border-top-width: 0.18em !important;
}

.bg-scimago-gradient {
  background: rgb(255, 255, 255);
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 153, 117, 1) 12%,
    rgba(255, 66, 0, 1) 50%
  );
  border-top-width: 0.18em !important;
}

.bg-crossref-gradient {
  border-top-width: 0.18em !important;
  border-top-color: #f87902 !important;
}

.tab-color-crossref-detail {
  border-top-width: 0.4em !important;
  border-top-color: #f87902 !important;
}

.tab-color-openalex-detail {
  border-top-width: 0.4em !important;
  border-top-color: gray !important;
}

.tab-color-doaj-detail {
  border-top-width: 0.4em !important;
  border-top-color: #663004 !important;
}

.tab-color-semantic-detail {
  border-top-width: 0.4em !important;
  border-top-color: #071237 !important;
}

.tab-color-dimensions-detail {
  border-top-width: 0.4em !important;
  border-top-color: #3c63e5 !important;
}

.tab-color-altmetric-detail {
  border-top-width: 0.4em !important;
  border-top-color: #8f74ca !important;
}

.tab-color-redib-detail {
  border-top-width: 0.4em !important;
  border-top-color: #8a0f0f !important;
}
.tab-color-wos-detail {
  border-top-width: 0.4em !important;
  border-top-color: #4167e6 !important;
}
.tab-color-scopus-detail {
  border-top-width: 0.4em !important;
  border-top-color: #ffd622 !important;
}
.tab-color-scimago-detail {
  border-top-width: 0.4em !important;
  border-top-color: #ff8623 !important;
}

.bagde-color-wos {
  background: #4167e6 !important;
}

.bagde-color-crossref {
  background: #f87902 !important;
}

.baged-color-doaj {
  background: #663004 !important;
}
.body {
  // display: flex;
  // flex-direction: column;
  // min-height: 70vh;
  // // display: grid;
  // // grid-template-columns: auto;
  display: flex;
  flex-direction: column;
  min-height: 82vh;
  margin: 0 auto;
  max-width: 1200px;
  padding: 1rem;
}

.footBar {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: auto;
  min-height: 10vh;
  background-color: rgba(var(--bs-light-rgb), var(--bs-bg-opacity)) !important;
  --bs-bg-opacity: 1;
  width: 100%;
  border-top: solid 1px black;
}

.ReactCollapse--collapse {
  transition: height 750ms;
}

.MdExpandMore:hover {
  border: 1px solid #ccc;
  border-radius: 50%;
  padding: 3px;
}

.footerImgContainer {
  width: 20%;
  //text-align: center;
}

.footerImg {
  width: 16rem;
}

/* customButton.css */
.nav-link-font {
  color: #f08080 !important;
  font-weight: bold !important;
}

.nav-link-font:hover {
  color: rgb(65, 15, 60) !important;
}

// .custom-btn {
//   background-color: #f08080 !important; /* Color rosa */
//   border-color: #f850a4 !important;
//   color: #ffffff !important;
// }

.custom-btn {
  color: #f08080 !important;
  //background-color: #ff80c0 !important; /* Color rosa más claro */
  background-color: white !important;
  border-color: #f08080 !important;
  font-weight: 00 !important;
}

.custom-btn:hover {
  background-color: #f08080 !important; /* Color rosa */
  border-color: #fa7fbc !important;
  color: #ffffff !important;
}

.custom-btn:active {
  background-color: #585858 !important; /* Color rosa más oscuro */
  border-color: #f08080 !important;
}

.custom-btn:focus,
.custom-btn.focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 105, 180, 0.5) !important;
}

.radio-buton-search {
  color: var(--bs-btn-active-color);
  background-color: #fb6f92;
  border-color: var(--bs-btn-active-border-color);
}

.back-to-top {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 99;
  scale: 2.5;
  color: linear-gradient(
    97deg,
    rgba(255, 205, 178, 1) 16%,
    rgba(255, 180, 162, 1) 58%,
    rgba(229, 152, 155, 1) 75%,
    rgba(181, 131, 141, 1) 92%
  );
}

.back-to-top:hover {
  color: #f08080;
}

.form-check-input:checked {
  background-color: #f08080 !important;
  border-color: #f08080 !important;
}

.only-export-border {
  border-color: #f08080 !important;
}

// bootstrap
@import "~bootstrap/scss/bootstrap.scss";
